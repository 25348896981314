<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        You need
        <number-value :value="mol" unit="\text{mol}" />
        of fructose for a reaction. If you have a
        <number-value :value="conc" unit="\text{M}" />
        solution of the sugar, how many mL of the solution should you add to your reaction flask?
      </p>

      <calculation-input
        v-model="inputs.volume"
        prepend-text="$\text{Volume:}$"
        append-text="$\text{mL}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';

export default {
  name: 'Question386',
  components: {
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        volume: null,
      },
    };
  },
  computed: {
    conc() {
      return this.taskState.getValueBySymbol('conc').content;
    },
    mol() {
      return this.taskState.getValueBySymbol('mol').content;
    },
  },
};
</script>
